import { useEffect } from 'react';

const useScript = (location) => {
  useEffect(() => {
    const script = document.createElement('script');

    const dutchieURL = 
        location === "cambridge" ? 'https://dutchie.com/api/v2/embedded-menu/6125173fbb388500bebb8805.js' :
        //location === "london" ? null :
        location === 'guelph' ? 'https://dutchie.com/api/v2/embedded-menu/5fd9060a3b500200eb20f810.js' :
        'https://dutchie.com/api/v2/embedded-menu/6125173fbb388500bebb8805.js?chainLocations=true' //Default set to guelph


    script.src = dutchieURL;
    script.async = true;

    document.body.appendChild(script);

    return () => {
    document.body.removeChild(script);
    };

  }, [location]);
};

export default useScript;
